<template>
    <layout class="wrapper" @search="handleSearchEvent">
        <!--    条件筛选    -->
        <div v-if="subjectList.length" class="filter-block">
            <div class="filter-content pb5">
                <filter-tab title="学科"  :selected="subjectIndex" :list="subjectList" :isShowMore="true" @clickItem="handleFilterEvent('subjectIndex',$event)"></filter-tab>
            </div>
        </div>
         <!-- 筛选条件 -->
        <div v-if="subjectIndex != -1 || $route.query.search_name" class="filter-condition active">
            <div class="container flex y-center x-left">
                <div class="search-text">搜索条件：</div>
                <a-breadcrumb class="search-breadcrumb">
                    <a-breadcrumb-item v-if="$route.query.search_name">{{ $route.query.search_name }}</a-breadcrumb-item>
                    <a-breadcrumb-item v-if="subjectIndex != -1">{{ subjectList[subjectIndex].operate_name }}
                    </a-breadcrumb-item>
                </a-breadcrumb>
                <a-button class="clear-btn" @click="handleClearEvent"
                    style="margin-left:20px;" size="small">清除</a-button>
            </div>
        </div>
        <!--  内容部分  -->
        <div class="content flex x-left">
            <div class="left-box">
                <a-spin :spinning="loading" tip="Loading...">
                    <div class="list-items">
                        <text-item path="/guide-list-detail" type="2" gap="20" v-for="(item,index) in listData" :key="index" :item="item"></text-item>
                        <a-empty v-if="!listData.length && !loading" />
                    </div>
                </a-spin>
                <div class="t-c mt30 guide-pagination" v-if="listCount > 0">
                    <a-pagination :total="listCount" v-model:current="params.page" :page-size="params.limit" :show-size-changer="false" show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                </div>
            </div>
            <div class="right-box flex-item">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
    </layout>
</template>

<script>
import layout from '@/components/layout/index';
import layoutRight from '@/components/layout/layout-right';
import filterTab from '@/components/filter-tab.vue'
import textItem from '@/components/text-item.vue';
import commonTitle from '@/components/common-title';
import verticalMediaItem from '@/components/vertical-media-item.vue'
import liveItem from '@/components/live-item.vue';
import { jsonFormat } from '@/utils/jsonFormat.js';
import { mapState } from 'vuex'
import storage from 'store'
let layoutRightConfig = {
    guide: {
        show: true, // 是否显示
        title: '指南解读', // 标题
        methods: "get",
        moreLink:'/video-list?category_id=88',
        data: {
            page: 1,
            limit: 4,
            category_id: 88
        },   // 参数条件
    },
    meeting: {
        show: true, // 是否显示
        title: '本月会议', // 标题
        methods: 'get',
        moreLink: '/meeting-list?year='+(new Date()).getFullYear()+'&month=' + ((new Date()).getMonth() + 1),
        data: {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            page: 1,
            pageSize: 6,
            is_now_year_meeting: 1,
            subject_ids: storage.get('subjectIds') || ''
        },   // 参数条件
    },
    live: {
        show: true, // 是否显示
        methods: 'get',
        title: '推荐直播', // 标题
        data: {
            page: 1,
            limit: 3
        },   // 参数条件
    },
};
export default {
    name: 'GuideList',
    components: {
        layout, filterTab, textItem, commonTitle, verticalMediaItem, liveItem, layoutRight
    },
    data() {
        return {
            layoutRightConfig:{},
            // 临床指南列表请求参数
            params: {
                page: 1,
                limit: 15,
                operate_id: storage.get('operate_id') || '',
                open_id: ''
            },
            listCount: 0,
            subjectIndex: -1,
            subjectList: [],
            listData: [],
            playBackList: [
                { title: '1' },
                { title: '1' },
            ],
            loading: true,
        }
    },
    created() {
        this.getSubject();
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        // 清除事件
        handleClearEvent() {
            this.subjectIndex = -1
            if (this.$route.query.search_name) {
                this.$router.replace({
                    path: '/guide-list'
                })
            }
            this.handleFilterEvent();
        },
        handleSearchEvent(){
            this.params.page = 1
            this.getGuideList()
        },
        // 获取临床指南列表
        getGuideList() {
            this.loading = true
            let data = { ...this.params };
            let { search_name } = this.$route.query;
            search_name && (data['keyword'] = search_name);
            this.request.post('GuideList', data).then(res => {
                let obj = {
                    id: 'guide_id',
                    news_title: 'title_cn',
                    create_time: 'publish_date',
                    source: 'reference',
                }
                this.listCount = Number(res.data.count)
                let listData = jsonFormat(res.data.list, obj)
                listData.forEach(item => {
                    item.class_type = 0
                    item.typeName = '指南'
                })
                this.listData = listData
                this.loading = false
            })
        },
        // 获取全部学科
        getSubject() {
            // url筛选学科
            let { subject_id } = this.$route.query;
            // 用户自己关注的学科
            subject_id = subject_id || this.userInfo.operate_subject;
            this.$store.dispatch('getSubjectList').then(res => {
                // 学科列表
                let subjectList = [];
                res.data.forEach((item,index) => {
                    item.name = item.operate_name;
                    if(subject_id == item.id){
                        this.subjectIndex = index;
                    }
                    subjectList.push(item);
                })
                this.subjectList = subjectList;

                // 触发修改事件
                this.handleFilterEvent();
            })
        },
        // 学科切换
        handleFilterEvent(key, e) {
            key && (this[key] = e);
            this.params.page = 1;

            // 用户url学科或者已选学科
            let subject_ids = this.$route.query.subject_id;
            let operate_subject = subject_ids;

            // 切换后的学科
            if(this.subjectIndex != -1){
                subject_ids = this.subjectList[this.subjectIndex].subject_ids;
                operate_subject = this.subjectList[this.subjectIndex].id;
                this.params.operate_id = operate_subject;
            } else {
                delete this.params.operate_id;
            }

            // 右侧数据修改
            layoutRightConfig.guide.data.subject_id = subject_ids;
            layoutRightConfig.live.data.subject_ids = subject_ids;
            layoutRightConfig.meeting.data.subject_ids = subject_ids;
            this.layoutRightConfig = JSON.parse(JSON.stringify(layoutRightConfig));

            this.getGuideList()
        },
        // 页码切换
        handlePageChangeEvent(page, pageSize) {
            window.scrollTo(0,0);
            this.params.page = page
            this.params.limit = pageSize
            this.getGuideList()
        },
    },
}
</script>
<style lang="less" scoped>
.filter-block {
    width: 100%;
    padding-top:10px;
    background: #fff;
    border-bottom:5px solid #f2f4fa;
    overflow: hidden;
    .filter-content {
        width: 1200px;
        margin: 0 auto;
    }
}
.filter-condition {
    background: #f2f4fa;
    overflow: hidden;
    height: 8px;
    box-sizing: content-box;
    &.active {
        height: 50px;
        line-height: 50px;
    }
    /deep/.ant-breadcrumb>span:last-child {
        color: inherit;
    }
}
.content {
    width: 1240px;
    padding: 20px;
    box-sizing: border-box;
    margin: 0 auto;
    .left-box {
        width: 770px;
        .list-items {
            min-height: 500px;
        }
    }
    .right-box {
        margin-left: 32px;
    }
}
.ml0 {
    margin-left: 0;
}
@media screen and (max-width:768px){
    .filter-block{
        position: sticky;
        top:50px;
        z-index: 9;
        padding-top:0;
        bottom:0;
        .filter-content{
            padding-bottom:0;
        }

    }
    .filter-condition.active{
        height:40px;
        line-height:40px;
        .container{
            padding:0 10px;
            display: flex;
        }
    }
    .content{
        margin-top: 10px;
        display: block;
        width: 100%;
        padding: 10px 10px 0;
        .left-box{
            width: 100%;
            .list-items{
                min-height: 0;
            }
            .guide-pagination{
                margin-top: 0;
            }
        }
        .right-box{
            margin-left: 0;
            margin-top: 60px;
        }
    }
}
</style>
